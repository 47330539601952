<template>
  <div class="quotationView">
    <div class="quotationTitle">
      <div class="Ocean-freight-FCL2">
        <div class="icon-div">
          <svg-icon icon-class="oceanFreight2"></svg-icon>
          <div class="icon-line"></div>
          <svg-icon :icon-class="form.quotationType"></svg-icon>
        </div>
      </div>
      <div class="quotationLeft">
        <div class="title">
          {{ quotationTypeObj[form.quotationMainType] }} -
          {{ quotationTypeObj[form.quotationType] }}
        </div>
        <div class="line line1">
          <span v-if="form.fromType == 0">{{ form.fromCyPortName }}</span>
          <span v-if="form.fromType == 1"
            >{{ form.fromDoorCityName }},{{ form.fromDoorCountry }}</span
          >
          <svg-icon icon-class="arrows"></svg-icon>
          <span v-if="form.toType == 0">{{ form.toCyPortName }}</span>
          <span v-if="form.toType == 1"
            >{{ form.toDoorCityName }},{{ form.toDoorCountry }}</span
          >
        </div>
        <div
          class="line"
          v-for="item in JSON.parse(form.containerDatas)"
          :key="item.containerSize"
        >
          <div class="ft">{{ item.containerSize }} x {{ item.count }}</div>
        </div>
        <div class="line line3">{{ $t("lang.NText76") }}</div>
        <div
          class="line"
          v-if="$i18n.locale == 'zh_cn' && form.deliveryDate !== ''"
        >
          {{ form.deliveryDate.slice(0, 10) }}
        </div>
        <div
          class="line"
          v-if="$i18n.locale == 'en_us' && form.deliveryDate !== ''"
        >
          {{ utils.zhDate_to_enDate(form.deliveryDate).slice(0, 15) }}
        </div>
      </div>
      <div class="quotationRight" v-if="form.quotationAmountType == 1">
        <div class="line1">Total</div>
        <div v-for="(item, index) in form.headAmounts" :key="index + 'h'">
          <div class="headAmounts">
            <div class="headCurrency">{{ item.headCurrency }}:</div>
            <div class="headAmount">{{ item.headAmount }}</div>
          </div>
        </div>
        <!-- <div class="line line4">Expiry date:</div>
        <div
          class="line line5"
          v-if="$i18n.locale == 'zh_cn' && form.expiredDate !== ''"
        >
          {{ form.expiredDate.slice(0, 10) }}
        </div> -->
        <!-- <div
          class="line line5"
          v-if="$i18n.locale == 'en_us' && form.expiredDate !== ''"
        >
          {{ utils.zhDate_to_enDate(form.expiredDate).slice(0, 15) }}
        </div> -->
      </div>
      <div class="quotationRight" v-if="form.quotationAmountType == 0">
        <div v-if="listData[0].lInevitable.length > 0">
          <div class="line1">{{ $t("lang.NText315") }}</div>
          <div class="" v-for="(item, index) in form.headAmounts" :key="index">
            <div class="headAmounts" v-if="item.amountType == 0">
              <div class="headCurrency">{{ item.headCurrency }}:</div>
              <div class="headAmount">{{ item.headAmount }}</div>
            </div>
          </div>
        </div>
        <div v-if="listData[1].lInevitable.length > 0">
          <div class="line1">{{ $t("lang.NText316") }}</div>
          <div class="" v-for="(item, index) in form.headAmounts" :key="index">
            <div class="headAmounts" v-if="item.amountType == 1">
              <div class="headCurrency">{{ item.headCurrency }}:</div>
              <div class="headAmount">{{ item.headAmount }}</div>.
              
            </div>
          </div>
        </div>
        <div v-if="listData[2].lInevitable.length > 0">
          <div class="line1">{{ $t("lang.NText317") }}</div>
          <div class="" v-for="(item, index) in form.headAmounts" :key="index">
            <div class="headAmounts" v-if="item.amountType == 2">
              <div class="headCurrency">{{ item.headCurrency }}:</div>
              <div class="headAmount">{{ item.headAmount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list" v-for="(item, index) in listData" :key="index">
      <div class="list-title">
        {{ item.name }}
      </div>
      <dwlist
        v-if="item.lInevitable.length > 0"
        source="local"
        :defaultData="item.lInevitable"
        :initDataFlag="false"
        :pagination="false"
      >
        <el-table-column prop="chargeItemName" :label="$t('lang.NText318')">
        </el-table-column>
        <el-table-column prop="chargeItemPrice" :label="$t('lang.NText79')">
        </el-table-column>
        <el-table-column prop="chargeItemQty" :label="$t('lang.NText80')">
        </el-table-column>
        <el-table-column prop="chargeItemAmount" :label="$t('lang.BText118')">
        </el-table-column>
        <el-table-column prop="chargeItemCurr" :label="$t('lang.NText81')">
        </el-table-column>
        <el-table-column prop="remarks" :label="$t('lang.NText319')">
        </el-table-column>
      </dwlist>
      <div class="mayBe" v-if="item.Adhoc.length > 0">
        <div class="mayBe-title">{{ $t("lang.NText320") }}</div>
        <div class="mayBe-list">
          <div
            class="Adhoc"
            v-for="(item2, index2) in item.Adhoc"
            :key="index2"
          >
            <div class="chargeItemName">{{ item2.chargeItemName }}</div>
            <div class="chargeItemCurr">{{ item2.chargeItemCurr }}</div>
            <div class="chargeItemAmount">{{ item2.chargeItemAmount }}</div>
            <div class="unit">{{ item2.unit }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="Terms">
      <div class="TermsTitle">{{ $t("lang.NText82") }}</div>
      <div
        class="term"
        v-for="(item, index) in form.terms ? JSON.parse(form.terms).list : []"
        :key="index + 't'"
      >
        <div class="No"></div>
        <div class="contant">
          <div class="line" v-for="(val, index2) in item" :key="index2">
            {{ val }}
          </div>
        </div>
      </div>
      <div
        class="term"
        v-for="(item, index) in form.terms ? JSON.parse(form.terms).custom : []"
        :key="index + 'te'"
      >
        <div class="No"></div>
        <div class="contant">
          <div class="line">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import utils from "../../utils/util";
export default {
  components: {
    dwlist,
  },
  props: {
    quotationData: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {},
      quotationTypeObj: {},
      listData: [
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
      ],
      utils: utils,
      terms: {
        custom: "染色体",
        list: [
          {
            label: { name: "system_term_first", value: "系统条款1" },
            value: "系统条款1",
          },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.quotationData) {
        this.form = JSON.parse(this.quotationData);
        this.form.charges.forEach((item) => {
          if (item.chargeItemType == 0) {
            this.listData[0].name = this.$t("lang.BText23");
            if (item.chargeType == 0) {
              this.listData[0].Adhoc.push(item);
            } else {
              this.listData[0].lInevitable.push(item);
            }
          }
          if (item.chargeItemType == 1) {
            this.listData[1].name = this.$t("lang.BText119");
            if (item.chargeType == 0) {
              this.listData[1].Adhoc.push(item);
            } else {
              this.listData[1].lInevitable.push(item);
            }
          }
          if (item.chargeItemType == 2) {
            this.listData[2].name = this.$t("lang.BText26");
            if (item.chargeType == 0) {
              this.listData[2].Adhoc.push(item);
            } else {
              this.listData[2].lInevitable.push(item);
            }
          }
          if (item.chargeItemType == "" || item.chargeItemType == "3") {
            if (item.chargeType == 0) {
              this.listData[2].Adhoc.push(item);
            } else {
              this.listData[2].lInevitable.push(item);
            }
          }
        });
      }
      this.$session("quotationType").get((value) => {
        value.forEach((item) => {
          this.quotationTypeObj[item.dictValue] = item.dictLabel;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.quotationView {
  width: 900px;
  margin: auto;
  text-align: left;
  .quotationTitle {
    display: flex;
    border-bottom: 2px solid $mainColor;
    padding-bottom: 10px;
    .Ocean-freight-FCL2 {
      display: flex;
      .icon-div {
        height: 80px;
        width: 160px;
        display: flex;
        align-items: center;
        background-color: $mainColor;
        .svg-icon {
          height: 40px;
          width: 40px;
          padding: 0 20px;
          color: #fff;
          fill: #fff;
          stroke: #fff;
        }
      }
      .icon-line {
        width: 2px;
        height: 40px;
        background: #fff;
      }
    }
    .quotationLeft {
      font-size: 30px;
      color: $mainColor;
      text-align: left;
      padding-right: 20px;
      margin-left: 20px;
      width: 430px;

      .title {
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        text-align: left;
        padding-bottom: 15px;
      }
      .line {
        margin-bottom: 10px;
        font-size: 20px;
        .ft {
          line-height: 15px;
        }
      }
      .line1 {
        display: flex;
        align-items: center;
        .svg-icon {
          color: $background2;
          height: 14px;
          width: 14px;
          padding: 0 20px;
        }
      }
      .line3 {
        font-size: 14px;
        color: $fontColor1;
        margin-bottom: 4px;
        margin-top: 10px;
      }
    }
    .quotationRight {
      text-align: left;
      width: 270px;
      .line1 {
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        color: #fff;
        background-color: $background2;
        padding-left: 10px;
        margin-bottom: 10px;
      }
      .headAmounts {
        color: $background2;
        display: flex;
        font-weight: bold;
        margin: 10px;
        justify-content: space-between;
        .headCurrency {
          width: 60px;
          padding-left: 10px;
          font-size: 16px;
          text-align: left;
        }
        .headAmount {
          width: 190px;
          text-align: right;
          padding-right: 10px;
          font-size: 16px;
        }
      }
      .line2 {
        border-top: 2px solid $background2;
      }
      .line3 {
        border-bottom: 2px solid $background2;
      }
      .line4 {
        font-size: 14px;
        color: $fontColor1;
        line-height: 20px;
        margin-top: 10px;
      }
      .line5 {
        color: $mainColor;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
  .list {
    margin: 20px 0;
    font-weight: bold;
    font-family: "montserratLight";

    .ChargeItem {
      color: $mainColor !important;
    }
    .el-table {
      color: $fontColor2 !important;
      border-right: 1px solid;
    }
    .el-table th.el-table__cell {
      color: $mainColor !important;
    }
    .el-table--fit {
      border-right: 0 !important;
    }
    .list-title {
      font-size: 20px;
      color: $mainColor;
      text-align: left;
      line-height: 40px;
      font-family: "montserratregular";
    }
    .mayBe {
      color: #9b9b9b;
      width: 450px;
      .mayBe-title {
        font-size: 16px;
        line-height: 40px;
        border-bottom: 1px solid #9b9b9b;
        margin-bottom: 10px;
      }
      .mayBe-list {
        .Adhoc {
          font-size: 14px;
          display: flex;
          line-height: 30px;
          .chargeItemName {
            width: 200px;
          }
          .chargeItemCurr {
            width: 50px;
          }
          .chargeItemAmount {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .Terms {
    text-align: left;
    font-size: 14px;
    margin-top: 60px;
    padding-bottom: 60px;

    .TermsTitle {
      color: $mainColor;
      margin-bottom: 20px;
    }
    .term {
      color: $fontColor2;
      display: flex;
      font-family: "montserratLight";
      .line {
        margin-bottom: 10px;
        line-height: 20px;
      }
      .line3 {
        color: $fontColor1;
      }
      .No {
        height: 10px;
        min-width: 10px;
        background-color: $mainColor;
        margin-right: 20px;
        margin-top: 5px;
      }
    }
  }
}
</style>