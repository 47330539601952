var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dwSteps"},_vm._l((_vm.stepsData.list),function(item,index){return _c('div',{key:index,staticClass:"stepsItem",class:{
      rightSteps:
        item.Status == 'Quote' ||
        item.Status == 'Quoted' ||
        item.Status == 'Quoting' ||
        item.Status == 'Audit' ||
        item.Status == 'Auditing' ||
        item.Status == 'Audited' ||
        item.Status == 'Shipping' ||
        item.Status == 'Shipped'||
        item.Status == '报价' ||
        item.Status == '完成报价' ||
        item.Status == '报价中' ||
        item.Status == '审核' ||
        item.Status == '审核中' ||
        item.Status == '已审核' ||
        item.Status == '出运' ||
        item.Status == '出运中' ||
        item.Status == '已出运',
      finished: item.user,
      blue: item.state == '30',
    }},[_c('div',{staticClass:"stepItem-content"},[_c('div',{staticClass:"stepsItem-title"},[_vm._v(" "+_vm._s(item.Status)+" "),(item.type && item.code)?_c('span',[_vm._v(_vm._s(item.code))]):_vm._e()]),_c('div',{staticClass:"stepsItem-line"}),(item.endTime)?_c('div',{staticClass:"stepsItem-time"},[_vm._v(" "+_vm._s(item.user)+", "),(_vm.$i18n.locale == 'en_us')?_c('span',[_vm._v(_vm._s(_vm.zhDate_to_ehDate(item.endTime)))]):_c('span',[_vm._v(_vm._s(item.endTime))])]):_c('div',{staticClass:"stepsItem-time"},[_vm._v(" "+_vm._s(item.deadline)+" ")])]),(item.state == '20')?_c('svg-icon',{attrs:{"icon-class":"check"}}):_vm._e(),(item.state == '10')?_c('div',{staticClass:"noCheck"}):_vm._e(),(item.state == '30')?_c('div',{staticClass:"Checking"}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }