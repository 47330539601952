<template>
  <el-dialog
    :visible.sync="showMyTem"
    class="MyTemDialog"
    width="70%"
    @close="closeDialog"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div class="MyTemDialogHeader">
      <div class="title">
        {{$t('lang.QText90')}} 
        <div class="number">{{ listData.total }}/10</div>
      </div>
      <div class="close" @click="CancelTem()">
        <svg-icon icon-class="close"></svg-icon>
      </div>
    </div>
    <div class="MyTemDialogMain">
      <el-table
        :data="listData"
        @row-click="rowClick"
        v-loading="loading"
        ref="table"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :label="$t('lang.BText98')"
          width="80"
          :index="indexMethod"
        >
        </el-table-column>
        <el-table-column prop="templateName" :label="$t('lang.QText92')">
          <template slot-scope="scope">
            <div v-if="scope.row.templateName">
              {{ scope.row.templateName }}
            </div>
            <div v-else class="emptySlot">Empty slot</div>
          </template>
        </el-table-column>
        <el-table-column prop="Type" :label="$t('lang.BText8')" width="100">
          <template slot-scope="scope">
            <div class="FCL" v-show="scope.row.bookingType == 'FCL'">
              <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
              <div class="line"></div>
              <svg-icon class="typeIcon" icon-class="FCL"></svg-icon>
            </div>
            <div class="LCL" v-show="scope.row.bookingType == 'LCL'">
              <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
              <div class="line"></div>
              <svg-icon class="typeIcon" icon-class="LCL"></svg-icon>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cargoInfo" :label="$t('lang.BText37')">
          <template slot-scope="scope">
            <div v-if="scope.row.containerType">
              <div
                v-for="(item, index) in JSON.parse(scope.row.containerType)"
                :key="index"
              >
                {{ item.containerTypOne }}*{{ item.containerTypTwo }}
              </div>
            </div>
            <div class="tot">
              {{ scope.row.totalVolume }} {{ scope.row.totalVolumeUnit }}
              {{ scope.row.totalWeight }} {{ scope.row.totalWeightUnit }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateDate" :label="$t('lang.BText38')">
        </el-table-column>
        <el-table-column prop="createBy" :label="$t('lang.BText39')">
        </el-table-column>
        <el-table-column fixed="right">
          <template slot-scope="scope" v-if="scope.row.createDate">
            <svg-icon
              class="delete-icon"
              @click.stop="Delete(scope.row)"
              icon-class="delete"
            ></svg-icon>
          </template>
        </el-table-column>
      </el-table>
      <dw-check-box
        :showCheckBox="showDelect"
        @Cancel="Cancel"
        @Save="deleteDraft"
        :primaryButton="$t('lang.BText40')"
        :CancelButton="$t('lang.QText79')"
      >
        {{$t('lang.BText99')}}
      </dw-check-box>
      <dw-check-box
        :showCheckBox="showSave"
        @Cancel="Cancel"
        @Save="Save"
        :primaryButton="$t('lang.SText13')"
        :CancelButton="$t('lang.QText79')"
      >
        <div class="line1">{{$t('lang.QText92')}}  *</div>
        <el-input class="nameInput" v-model="templateName"></el-input>
        <div class="line2" v-if="slot > listData.total">
          {{$t('lang.BText123')}}  {{ slot }}?
        </div>
        <div class="line2" v-else>
          {{$t('lang.BText123')}} {{ slot }} <br />{{$t('lang.BText102')}} 
        </div>
      </dw-check-box>
    </div>
  </el-dialog>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
import test from "../../test/test";
export default {
  components: {
    dwlist,
    dwCheckBox,
  },
  props: {
    CancelButton: {
      type: String,
      default: "Cancel",
    },
    primaryButton: {
      type: String,
      default: "Save",
    },
    showMyTem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDelect: false,
      slot: null,
      showSave: false,
      listData: [],
      templateName: "",
      id: "",
      deleteId: ""
    };
  },
  watch: {
    showMyTem: function (val) {
      if (val) {
        this.getTemplateList();
      }
    },
  },
  created() {
    this.getTemplateList();
  },
  methods: {
    CancelTem() {
      this.$emit("Cancel");
    },
    Save() {
      if (!this.templateName) {
        this.$notify.error({
          title: this.$t('lang.SText143'),
          offset: 100,
          duration: 3000,
        });
        return;
      }
      this.$emit("Save", {
        slot: this.slot,
        templateName: this.templateName,
        id: this.id,
      });
      this.showSave = false;
    },
    closeDialog() {},
    Cancel() {
      this.showDelect = false;
      this.showSave = false;
    },
    rowClick(row) {
      this.slot = row.serialNumber;
      this.id = row.id;
      this.showSave = true;
    },
    getTemplateList() {
      let url = this.source.booking_api.templateList;
      this.loading = true;
      this.$ajax
        .cPost(url)
        .then((res) => {
          this.loading = false;
          if (res.data.result) {
            this.listData = [...[], ...res.data.list];
            this.listData.total = res.data.list.length;
            for (let index = this.listData.total + 1; index <= 10; index++) {
              this.listData.push({ serialNumber: this.listData.total + 1 });
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Delete(data) {
      this.showDelect = true;
      this.deleteId = data.id;
    },
    deleteDraft() {
      let url = this.source.booking_api.deleteTemplate;
      this.loading = true;
      this.$ajax
        .cPost(url, { id: this.deleteId })
        .then((res) => {
          this.loading = false;
          this.showDelect = false;
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.getTemplateList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang='scss'>
@import "../../assets/base.scss";
.MyTemDialog {
  font-family: "montserratregular";
  top: 2% !important;
  .el-dialog__header {
    display: none;
  }
  .MyTemDialogHeader {
    display: flex;
    justify-content: space-between;
    .title {
      display: flex;
      color: $background2;
      font-size: 30px;
      margin-left: 20px;
      line-height: 30px;

      .number {
        font-size: 20px;
        line-height: 40px;
        margin-left: 30px;
      }
    }
    .svg-icon{
      fill: $mainColor;
      stroke: $mainColor;
    }
  }
  .MyTemDialogMain {
    font-size: 30px;
    padding: 20px 10px;
    color: $mainColor;
    line-height: 40px;
    text-align: left;
  }

  .svg-icon {
    height: 30px;
    width: 30px;
    color: $mainColor;
  }

  .emptySlot {
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    color: $borderColor1 !important;
  }

  .delete-icon {
    height: 20px;
    width: 20px;
    color: $background2;
  }
  .FCL,
  .LCL {
    display: flex;
    align-items: center;
    .typeIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    .line {
      height: 15px;
      background: $mainColor;
      width: 1px;
      margin-right: 10px;
    }
  }
}
</style>