<template>
  <div class="bookingReview">
    <div class="timeline" v-if="bookingId && viewType == 1">
      <dw-time-line
        styleType="2"
        :data="bookingStatusList"
        status="2"
      ></dw-time-line>
    </div>
    <div class="bookingReviewMain">
      <div class="bookingReviewMainTitle" v-if="bookingId">
        <div class="Ocean-freight-FCL">
          <div class="icon-div" v-if="viewType == 1 || hasQuotation">
            <svg-icon icon-class="oceanFreight"></svg-icon>
            <div class="icon-line"></div>
            <svg-icon icon-class="FCL"></svg-icon>
          </div>
          <div class="title" v-if="viewType == 1 || hasQuotation">
            {{ bookingData.airSea }} <br />
            {{ bookingData.bookingType }}
          </div>
        </div>
        <div class="shippingAuthorization" v-if="viewType == 1">
          <div class="text">{{ $t("lang.BText122") }}</div>
        </div>
        <div
          class="shippingAuthorization"
          :class="{ unClick: !hasQuotation }"
          v-if="viewType == 2"
        >
          <div class="text">{{ $t("lang.BText65") }}</div>
        </div>
      </div>
      <div v-if="viewType == 1">
        <div class="review-item">
          <div class="review-item-title font30">
            {{ $t("lang.BText77") }}
          </div>
          <div class="item2-content">
            {{ $t("lang.BText78") }} : {{ bookingData.referenceNo }}
          </div>
        </div>
        <div class="review-item">
          <div class="review-item-title font30">
            {{ $t("lang.BText17") }} / {{ $t("lang.BText3") }} /
            {{ $t("lang.BText22") }}
          </div>
          <div class="item2-content">
            <div class="review-line">
              <div class="review-item">
                <div class="review-item-title">{{ $t("lang.BText17") }} *</div>
                <!-- <div class="review-main-title2">
              Tianjin Woshang International Trade Company
              <div class="user">Partner</div>
            </div> -->
                <div class="review-item-main">
                  {{ bookingData.shipper }}
                </div>
              </div>
            </div>
            <div class="review-line">
              <div class="review-item">
                <div class="review-item-title">{{ $t("lang.BText3") }} *</div>
                <!-- <div class="review-main-title2">BEST VT INC.</div> -->
                <div class="review-item-main">
                  {{ bookingData.consignee }}
                </div>
              </div>
            </div>
            <div class="review-line">
              <div class="review-item">
                <div class="review-item-title">{{ $t("lang.BText22") }} *</div>
                <!-- <div class="review-main-title2">BEST VT INC.</div> -->
                <div class="review-item-main">
                  {{ bookingData.notifyParty }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="review-item">
          <div class="review-item-title font30">{{ $t("lang.QText38") }}</div>
          <div class="review-item-main">
            <span v-show="bookingData.incotermOne == 0"
              >{{ $t("lang.QText40") }}：</span
            ><span v-show="bookingData.incotermOne == 1"
              >{{ $t("lang.QText41") }}：</span
            >{{ bookingData.incotermTwo }}
          </div>
        </div>
        <div class="review-item">
          <div class="review-item-title font30">{{ $t("lang.BText23") }}</div>
          <div class="review-item-main">
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.BText24") }}:</div>
              <div class="item2-content">
                {{ bookingData.originPortName }}
              </div>
            </div>
            <div class="item2" v-if="bookingData.cargoReadyDay">
              <div class="item2-title2">{{ $t("lang.QText21") }}:</div>
              <div class="item2-content">
                <span v-if="$i18n.locale == 'en_us'">{{
                  zhDate_to_ehDate(bookingData.cargoReadyDay)
                }}</span
                ><span v-else>{{ bookingData.cargoReadyDay }}</span>
              </div>
            </div>
            <div class="item2-title1">{{ $t("lang.BText79") }}</div>
            <template v-if="bookingData.originPickUp == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText49") }}
              </div>
              <div class="item2-content2">
                {{ bookingData.originAddress }},
                {{ bookingData.originCityName }},
                {{ bookingData.originStateName }},
                {{ bookingData.originCountryRegionName }}
              </div>
            </template>
            <template v-if="bookingData.originCustomerClearance == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.BText25") }}
              </div>
            </template>
            <!-- <template v-if="bookingData.originBond == 1">
                <div class="line-key">
                  <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                  Bond
                </div>
                <div class="item2-content2">
                  <span v-if="bookingData.originBondValue == 0"
                    >single bond</span
                  >
                  <span v-if="bookingData.originBondValue == 1"
                    >annual bound</span
                  >
                </div>
              </template> -->
            <template v-if="bookingData.originInsurance == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText57") }}
              </div>
              <div class="item2-content2">
               {{ bookingData.originCurrency }} {{ bookingData.originValue }} 
              </div>
            </template>
            <template v-if="bookingData.originIsfFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                ISF
              </div>
            </template>
            <template v-if="bookingData.originAmsFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                AMS
              </div>
            </template>
            <template v-if="bookingData.originAciFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                ACI
              </div>
            </template>
            <template v-if="bookingData.originDocumentation == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText61") }}
              </div>
            </template>
            <template v-if="bookingData.originWarehouse == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText63") }}
              </div>
              <div class="item2-content2">
                {{ bookingData.originValueAddedSevice }}
              </div>
            </template>
          </div>
        </div>
        <div class="review-item">
          <div class="review-item-title font30">{{ $t("lang.BText26") }}</div>
          <div class="review-item-main">
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.BText27") }} :</div>
              <div class="item2-content">
                {{ bookingData.destinationDischargePortName }}
              </div>
            </div>
            <div class="item2" v-if="bookingData.destinationTargetDeliveryDate">
              <div class="item2-title2">{{ $t("lang.BText28") }} :</div>
              <div class="item2-content">
                <span v-if="$i18n.locale == 'en_us'">{{
                  zhDate_to_ehDate(bookingData.destinationTargetDeliveryDate)
                }}</span
                ><span v-else>{{
                  bookingData.destinationTargetDeliveryDate
                }}</span>
              </div>
            </div>
            <div class="item2-title1">{{ $t("lang.BText79") }}</div>
            <template v-if="bookingData.destinationDelivery == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText67") }}
              </div>
              <div class="item2-content2">
                {{ bookingData.destinationAddress }},
                {{ bookingData.destinationCityName }},
                {{ bookingData.destinationStateName }},
                {{ bookingData.destinationCountryRegionName }}
              </div>
            </template>
            <template v-if="bookingData.customerClearance == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.BText25") }}
              </div>
            </template>
            <template v-if="bookingData.destinationWarehouse == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText63") }}
              </div>
              <div class="item2-content2">
                {{ bookingData.destinationWarehouseValue }}
              </div>
            </template>
            <template v-if="bookingData.destinationBound == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                Bond
              </div>
              <div class="item2-content2">
                {{ bookingData.destinationBoundValue }}
              </div>
            </template>
          </div>
        </div>
        <div class="review-item">
          <div class="review-item-title font30">{{ $t("lang.BText29") }}</div>
          <div class="review-item-main">
            <div class="containerSize">
              <div
                class="ft"
                v-for="item in bookingData.containerType"
                :key="item.containerTypOne"
              >
                {{ item.containerTypOne }} * {{ item.containerTypTwo }},
              </div>
            </div>
          </div>
        </div>

        <div class="review-item">
          <div class="review-item-title font30">{{ $t("lang.BText104") }}</div>
          <div class="review-item-main">
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.QText43") }}:</div>
              <div class="item2-content">
                {{ bookingData.cargoTypeName }}
              </div>
            </div>
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.QText47") }} :</div>
              <div class="item2-content">
                <div
                  class="containerSize"
                  v-for="(item, index) in bookingData.cargos"
                  :key="index"
                >
                  <div class="ft">{{ item.commodityValueName }}</div>
                  <div class="num">{{ item.commodityHscode }};</div>
                </div>
              </div>
            </div>
            <div class="packingList" v-if="bookingData.cargoDetails == '0'">
              <div class="packingListTitle">{{ $t("lang.BText44") }}</div>
              <div
                class="group"
                v-for="(item, index) in bookingData.packings"
                :key="item.id"
              >
                <div class="GroupTitle">Group {{ index + 1 }}</div>
                <div class="packingListRight">
                  <div class="PoNo">
                    <span style="color: #b3b3b3"
                      >{{ $t("lang.BText45") }} : </span
                    ><span>{{ item.packageName }}</span>
                  </div>
                  <div class="commodity">
                    <div class="commodityNumber">
                      <span v-show="item.commodityType == 'Pallet'">{{
                        item.packingFour
                      }}</span
                      ><span v-show="item.commodityType == 'Carton'">{{
                        item.packageValue
                      }}</span>
                    </div>
                    <div class="commodityUtil">Carton</div>
                  </div>
                  <div
                    class="commodity"
                    v-show="item.commodityType == 'Pallet'"
                  >
                    <div class="commodityNumber">{{ item.packageValue }}</div>
                    <div class="commodityUtil">{{ item.commodityType }}</div>
                  </div>
                  <div class="commodity" v-if="item.packageType == 0">
                    <div class="commodityNumber">
                      {{ item.dimensionsL }}x{{ item.dimensionsW }}x{{
                        item.dimensionsH
                      }}
                    </div>
                    <div class="commodityUtil"> {{ item.dimensionsOne }}</div>
                    ,
                    <div class="commodityNumber">
                      {{ item.dimensionsWeight }}
                    </div>
                    <div class="commodityUtil">
                      {{ item.dimensionsWeightType }}
                    </div>
                    <div class="commodityType"> / {{ item.commodityType }}</div>
                  </div>
                  <div class="commodity" v-else>
                    <div class="commodityNumber">
                      {{ item.packageVolumeOne }}
                    </div>
                    <div class="commodityUtil">
                      {{ item.packageVolumeTwo }}
                    </div>
                    <div class="commodityNumber">
                      ,{{ item.packageVolumeThere }}
                    </div>
                    <div class="commodityUtil">
                      {{ item.packageVolumeFour }}
                    </div>
                    <div class="commodityType">/ {{ item.commodityType }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.QText28") }} :</div>
              <div class="item2-content">
                <div class="number">{{ bookingData.totalWeight }}</div>
                <div class="unit">{{ bookingData.totalWeightUnit }}</div>
              </div>
            </div>
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.QText29") }} :</div>
              <div class="item2-content">
                <div class="number">{{ bookingData.totalVolume }}</div>
                <div class="unit">{{ bookingData.totalVolumeUnit }}</div>
              </div>
            </div>
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.QText30") }} :</div>
              <div class="item2-content">
                <div class="number">{{ bookingData.totalQuantity }}</div>
                <div class="unit">{{ bookingData.totalQuantityUnit }}</div>
              </div>
            </div>
            <div class="item2">
              <div class="item2-title2">{{ $t("lang.BText51") }} :</div>
              <div class="item2-content">
                {{ bookingData.totalAddress }}
              </div>
            </div>
            <div
              class="item2"
              v-show="
                bookingData.totalBatteries == 1 ||
                bookingData.hazardousMaterial == 1 ||
                bookingData.totalOther == 1
              "
            >
              <div class="item2-title2">{{ $t("lang.BText30") }} :</div>
              <span v-if="bookingData.totalBatteries == 1">
                {{ $t("lang.QText32") }};
              </span>
              <span v-if="bookingData.hazardousMaterial == 1">
                {{ $t("lang.QText33") }};
              </span>
              <span v-if="bookingData.totalOther == 1">
                {{ $t("lang.QText34") }};
              </span>
            </div>
          </div>
        </div>

        <div class="review-item">
          <div class="review-item-title font30">{{ $t("lang.BText31") }}</div>
          <div class="review-item-main">
            <template v-if="bookingData.notification == 'Just my account'">
              <div class="item3">
                <div class="item2-title2">{{ $t("lang.BText32") }}</div>
                <div class="item2-content">
                  {{ bookingData.pricingBookingComfirmation }}
                  <!-- <div class="user"></div> -->
                </div>
              </div>
            </template>
            <template
              v-if="bookingData.notification == 'Multi-party cooperation'"
              ><div class="item3">
                <div class="item2-title2">{{ $t("lang.BText91") }}</div>
                <div class="item2-content">
                  {{ bookingData.bookingComfirmation }}
                  <!-- <div class="user"></div> -->
                </div>
              </div>
              <div class="item3">
                <div class="item2-title2">{{ $t("lang.BText94") }}</div>
                <div class="item2-content">
                  {{ bookingData.pricingOriginCharge }}
                  <!-- <div class="user"></div> -->
                </div>
              </div>
              <div class="item3">
                <div class="item2-title2">{{ $t("lang.BText95") }}</div>
                <div class="item2-content">
                  {{ bookingData.freightDestinationCharge }}
                  <!-- <div class="user"></div> -->
                </div>
              </div></template
            >
          </div>
        </div>
        <!-- <div class="review-item">
          <div class="review-item-title font30">Remark</div>
          <div class="item2-content">
            {{ bookingData.notificationRemark }}
          </div>
        </div> -->
        <div class="bottom-button" v-if="showButton">
          <dw-button type="successSmall" @click="SaveTem()"
            >{{ $t("lang.QText78") }}
          </dw-button>
          <dw-button type="infoSmall" @click="Edit()">{{
            $t("lang.QText77")
          }}</dw-button>

          <dw-button type="infoSmall" @click="Previous()" v-if="!bookingId">{{
            $t("lang.BText97")
          }}</dw-button>
          <dw-button
            type="primarySmall"
            @click="Submit()"
            :loading="saveLoading"
            v-if="!bookingId"
            >{{ $t("lang.text14") }}</dw-button
          >
        </div>
      </div>
    </div>
    <template-dialog
      :showMyTem="showMyTem"
      @Cancel="Cancel"
      @Save="SaveTemplate"
      v-loading="saveTemplateLoading"
    ></template-dialog>
  </div>
</template>

<script>
import dwTimeLine from "../../components/dwTimeline/dwTimeLine.vue";
import dwlist from "../../components/dwList/dwlist.vue";
import test from "../../test/test";
import TemplateDialog from "./templateDialog.vue";
import utils from "../../utils/util";
export default {
  components: {
    dwTimeLine,
    dwlist,
    TemplateDialog,
  },
  props: {
    bookingId: {
      type: String,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    bookingData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      bookingStatusList: [
        {
          name: "Pre-booking",
        },
        {
          name: "Pricing",
        },
        {
          name: "Pricing Confirm & Shipping authorization",
        },
        {
          name: "Shipping",
        },
      ],
      viewType: 1,
      listData: test.QuotationData,
      hasQuotation: false,
      showMyTem: false,
      saveTemplateLoading: false,
      saveLoading: false,
    };
  },
  created() {
    console.log(this.bookingData);
  },
  methods: {
    zhDate_to_ehDate(e) {
      return utils.zhDate_to_enDate(e);
    },
    Edit() {
      this.$emit("editBooking", this.bookingData);
    },
    SaveTem() {
      this.showMyTem = true;
    },
    Cancel() {
      this.showMyTem = false;
    },
    Previous() {
      this.$emit("clickPrevious");
    },
    Submit() {
      let that = this;
      this.saveLoading = true;
      that.$store.commit("api/SET_LOADING", true);
      let data = {
        ...{},
        ...that.bookingData,
      };
      if (typeof data.containerType == "object") {
        data.containerType = JSON.stringify(data.containerType);
      }
      if (typeof data.packings == "object") {
        data.packings = JSON.stringify(data.packings);
      }
      if (typeof data.cargos == "object") {
        data.cargos = JSON.stringify(data.cargos);
      }
      let url = "";
      if (data.id) {
        url = that.source.booking_api.bookingUpdate;
      } else {
        url = that.source.booking_api.addBooking;
      }
      if (data.cargoDetails == "1") {
        data.packings = [];
      }
      that.$ajax.cPost(url, data).then((res) => {
        if (res.data.result == "true") {
          that.$notify.success({
            title: res.data.message,
            offset: 100,
          });
        } else {
          that.$notify.error({
            title: res.data.message,
            offset: 100,
          });
        }
        this.$store.commit("api/SET_LOADING", false);
        this.saveLoading = false;
        // this.$router.push("/Quotation/quotation");
        this.$emit("SaveSuccess");
      });
    },

    SaveTemplate(data) {
      let that = this;
      let template = {
        ...{},
        ...that.bookingData,
      };
      that.saveTemplateLoading = true;
      template.templateType = "2";
      template.serialNumber = data.serialNumber;
      template.templateName = data.templateName;
      template.id = data.id;
      if (typeof template.containerType == "object") {
        template.containerType = JSON.stringify(template.containerType);
      }
      if (typeof template.packings == "object") {
        template.packings = JSON.stringify(template.packings);
      }
      if (typeof template.cargos == "object") {
        template.cargos = JSON.stringify(template.cargos);
      }
      that.$ajax
        .cPost(that.source.booking_api.addTemplateDraft, template)
        .then((res) => {
          that.saveTemplateLoading = false;
          if (res.data.result) {
            that.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            that.showMyTem = false;
          } else {
            that.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.bookingReview {
  .timeline {
    background-color: $background1;
    padding: 20px;
    border: 1px solid $borderColor1;
    .step {
      min-width: 250px !important;
    }
  }

  .bookingReviewMain {
    width: 900px;
    height: auto;
    color: $mainColor;
    margin: auto;
    text-align: left;
    font-size: 18px;
    min-height: 600px;
    padding-bottom: 40px;
  }

  .bookingReviewMainTitle {
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid $mainColor;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .Ocean-freight-FCL {
      height: 60px;
      display: flex;
      .icon-div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        border: 2px solid $mainColor;
        .svg-icon {
          height: 30px;
          width: 30px;
          padding: 0 15px;
          color: $mainColor;
        }
      }
      .icon-line {
        width: 1px;
        height: 30px;
        background: $mainColor;
      }
      .title {
        margin-left: 20px;
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        line-height: 34px;
      }
    }
    .shippingAuthorization {
      height: 60px;
      width: 200px;
      line-height: 20px;
      text-align: center;
      background-color: $mainColor;
      color: #fff;
      font-size: 18px;
      word-break: normal;
      cursor: pointer;
      display: table;
      .text {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .unClick {
      background-color: $borderColor1;
      color: $fontColor2;
    }
  }

  .review-item {
    margin-bottom: 40px;
    .review-item-title {
      font-size: 20px;
      border-bottom: 2px solid $mainColor;
      padding: 10px 0;
      margin-bottom: 20px;
    }
    .font30 {
      font-size: 30px;
      font-family: "SEMIBOLD";
    }
    .review-main-title2 {
      font-size: 14px;
      color: $fontColor2;
      display: flex;
      padding: 0 10px;
    }
    .user {
      background-color: $background2;
      color: #fff;
      padding: 0 3px;
      margin: 0 5px;
      white-space: nowrap;
      height: 16px;
      font-size: 12px;
      line-height: 16px;
    }
    .review-item-main {
      padding: 0 10px;
      .line-key {
        display: flex;
        flex: 3;
        width: 100%;
        height: 30px;
        line-height: 30px;
        margin-right: 20px;
        .line-key-icon {
          height: 16px;
          width: 16px;
          background: $mainColor;
          color: #fff;
          margin: 6px 12px 6px 0;
          display: flex;
          justify-content: center;
          .svg-icon {
            width: 10px;
            height: 10px;
            margin: auto;
          }
        }
      }
      .line-value {
        flex: 7;
        margin-right: 20px;
      }
    }
    .item2 {
      display: flex;
      margin: 10px 0;
      .item2-title2 {
        color: $fontColor1;
        white-space: nowrap;
        margin-right: 10px;
        width: 180px;
      }
      .item2-content {
        display: flex;
        color: $mainColor;
        .number {
          width: 100px;
          text-align: right;
        }
        .unit {
          color: $background2;
          margin-left: 5px;
        }
      }
      .line-key {
        display: flex;
        line-height: 30px;
        .line-key-icon {
          width: 10px;
          height: 10px;
          background: $mainColor;
          margin-right: 10px;
          margin: 10px 10px 10px 0;
        }
      }
    }

    .packingList {
      .packingListTitle {
        color: $fontColor3;
        font-family: "SEMIBOLD";
        border-bottom: 2px solid $borderColor1;
        width: 50%;
        padding-bottom: 10px;
      }
      .group {
        width: 50%;
        border-bottom: 2px solid $borderColor1;
        display: flex;
        padding: 10px 0;
        .GroupTitle {
          transform: rotate(90deg);
          width: 120px;
          height: 40px;
          margin-top: 40px;
          margin-left: -60px;
          color: $fontColor1;
          font-family: "montserratregular";
        }
        .packingListRight {
          line-height: 30px;
          .commodity {
            display: flex;
            .commodityUtil {
              margin-left: 4px;
              color: $background2;
            }
            .commodityType {
              font-size: 14px;
              line-height: 30px;
            }
          }
        }
      }
    }
    .item2-title1 {
      margin-top: 20px;
      line-height: 30px;
      color: $fontColor2;
      border-bottom: 2px solid $fontColor1;
      width: 50%;
    }
    .item2-content2 {
      margin-left: 20px;
      border-top: 1px solid $borderColor1;
      padding: 10px 0;
    }

    .item3 {
      .item2-title2 {
        margin-top: 20px;
        line-height: 30px;
        color: $fontColor2;
        border-bottom: 2px solid $borderColor1;
        padding-bottom: 8px;
        width: 50%;
      }
      .review-main-title2 {
        font-size: 14px;
        padding-left: 30px;
        margin-top: 10px;
      }
      .item2-content {
        padding: 10px 0 20px 30px;
        display: flex;
      }
    }
  }
  .containerSize {
    display: flex;
    flex-direction: row;
    .ft {
      margin-right: 10px;
    }
  }
  .review-line {
    display: flex;
    justify-content: space-between;
    .review-item {
      width: 48%;
    }
    .review-item-title {
      font-size: 20px;
      border-bottom: 2px solid $fontColor1;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
  .editButton:focus,
  .editButton {
    height: 64px;
    font-size: 30px;
    border: 2px solid $mainColor;
    color: $mainColor;
    border-radius: 0;
    background-color: #fff;
  }
  .editButton:hover {
    height: 64px;
    font-size: 30px;
    border: 2px solid $background2;
    color: #fff;
    background-color: $background2;
    border-radius: 0;
  }

  .booking-left-menu {
    position: absolute;
    top: 200px;
    left: -44px;
    .button {
      width: 146px;
      height: 60px;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* IE 9 */
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg);
      font-size: 20px;
      border: 2px solid $mainColor;
      line-height: 60px;
      background-color: #fff;
      color: $mainColor;
      cursor: pointer;
    }
    .booking-button {
      margin-top: 30px;
    }
    .quotation-button {
      margin-top: 100px;
    }
    .chatHistory-button {
      margin-top: 100px;
    }
    .button:hover,
    .is-check {
      background-color: $mainColor;
      color: $background2;
    }
  }

  .el-upload-dragger {
    width: 860px;
    border: 0;
    height: 492px;
    position: relative;
    background: #fff;
    z-index: 100;
    .el-upload__text {
      font-size: 60px;
      color: $fontColor2;
      line-height: 65px;
      text-align: center;
      margin-top: 100px;
    }
    .button {
      background-color: $mainColor;
      color: #fff;
      width: 400px;
      height: 64px;
      line-height: 64px;
      top: 220px;
      font-size: 20px;
      margin: 80px auto;
    }
  }

  .bottom-button {
    display: flex;
    justify-content: end;
    margin-top: 40px;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>