<template>
  <div class="dwSteps">
    <div
      class="stepsItem"
      v-for="(item, index) in stepsData.list"
      :key="index"
      :class="{
        rightSteps:
          item.Status == 'Quote' ||
          item.Status == 'Quoted' ||
          item.Status == 'Quoting' ||
          item.Status == 'Audit' ||
          item.Status == 'Auditing' ||
          item.Status == 'Audited' ||
          item.Status == 'Shipping' ||
          item.Status == 'Shipped'||
          item.Status == '报价' ||
          item.Status == '完成报价' ||
          item.Status == '报价中' ||
          item.Status == '审核' ||
          item.Status == '审核中' ||
          item.Status == '已审核' ||
          item.Status == '出运' ||
          item.Status == '出运中' ||
          item.Status == '已出运',
        finished: item.user,
        blue: item.state == '30',
      }"
    >
      <div class="stepItem-content">
        <div class="stepsItem-title">
          {{ item.Status }}
          <span v-if="item.type && item.code">{{ item.code }}</span>
        </div>
        <div class="stepsItem-line"></div>
        <div class="stepsItem-time" v-if="item.endTime">
          {{ item.user }},
          <span v-if="$i18n.locale == 'en_us'">{{
            zhDate_to_ehDate(item.endTime)
          }}</span
          ><span v-else>{{ item.endTime }}</span>
        </div>
        <div class="stepsItem-time" v-else>
          {{ item.deadline }}
        </div>
      </div>
      <svg-icon icon-class="check" v-if="item.state == '20'"></svg-icon>
      <div v-if="item.state == '10'" class="noCheck"></div>
      <div v-if="item.state == '30'" class="Checking"></div>
    </div>
  </div>
</template>

<script>
import utils from '../../utils/util'
export default {
  props: {
    stepsData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    flexData: {
      type: Array,
      default: function () {
        return [0, 1, 0, 0, 1];
      },
    },
  },
  created() {},
  methods:{
    zhDate_to_ehDate(e) {
      return utils.zhDate_to_enDate(e);
    },
  }
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.dwSteps {
  width: 100%;
  margin: auto;

  .stepsItem {
    display: flex;
    width: 100%;
    text-align: left;
    .stepItem-content {
      width: 50%;
      .stepsItem-title {
        width: 90%;
        font-size: 20px;
        color: $fontColor2;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .stepsItem-line {
        border-bottom: 1px solid $borderColor1;
        width: 100%;
      }
      .stepsItem-time {
        font-size: 14px;
        line-height: 26px;
      }
    }
    .svg-icon {
      height: 30px;
      width: 30px;
      margin: 20px 0 20px -30px;
    }
  }
  .finished {
    .stepItem-content {
      .stepsItem-title {
        color: $mainColor;
      }
    }
  }
  .noCheck {
    height: 22px;
    width: 22px;
    border: 4px solid $borderColor1;
    border-radius: 22px;
    margin: 16px 0 16px -30px;
    background-color: #fff;
  }
  .Checking {
    height: 22px;
    width: 22px;
    border: 4px solid $background2;
    border-radius: 22px;
    margin: 16px 0 16px -30px;
    background-color: #fff;
  }
  .rightSteps {
    flex-direction: row-reverse;
    text-align: right;
    .stepItem-content {
      .stepsItem-title {
        padding-left: 10%;
      }
    }
  }
  .blue .stepItem-content .stepsItem-title {
    color: $background2;
  }
}
</style>